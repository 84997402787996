import {ICrmbLeadCampanhaCleanDTO, ICrmbLeadCampanhaDTO, ICrmbLeadCampanhaFullDTO} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadCampanhaService = () => {
	const resourceURL = 'crmb/lead-campanha';

	return {
		...baseFindList<ICrmbLeadCampanhaCleanDTO, ICrmbLeadCampanhaDTO, ICrmbLeadCampanhaFullDTO>(resourceURL),
	};
};
