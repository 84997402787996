import {ICrmbLeadTipoCleanDTO, ICrmbLeadTipoDTO, ICrmbLeadTipoFullDTO } from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTipoService = () => {
	const resourceURL = 'crmb/lead-tipo';

	return {
		...baseFindList<ICrmbLeadTipoCleanDTO, ICrmbLeadTipoDTO, ICrmbLeadTipoFullDTO>(resourceURL),
	};
};
