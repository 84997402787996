import { useEffect, useMemo, useState } from 'react';

import * as Unicons from '@iconscout/react-unicons';
import PersonIcon from '@mui/icons-material/Person';
import RestoreIcon from '@mui/icons-material/Restore';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Checkbox } from 'antd';
import { format, parse } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { styles } from '../../assets/sass/styles';
import ModalRenderHTML from '../../components/ModalRenderHTML';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmbLeadHistoricoDTO, ICrmbLeadHistoricoTipoCleanDTO } from '../../models/happy-code-api.model';
import { useLeadHistoricoService } from '../../services/lead-historico.service';
import { useNotificationService } from '../../services/notification.service';
import { formatRemove3Horas } from '../../util/format';
import { TabPanel, a11yProps } from './index';
import { TipoHistoricoLead } from '../../enum/tipoHistoricoLead';

export const TimelineLead = () => {

  const [value, setValue] = useState(0);

  const { setIsGlobalLoading } = useGlobalContext();
  const { leadSelecionado, historicosAgrupados, state, fetchHistoricos, historicoResponse, onHistoricoPageChange } = useLeadContext();
  const leadHistoricoService = useLeadHistoricoService();
  const notification = useNotificationService();
  const [checkboxFiltro, setCheckboxFiltro] = useState({});


  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeSelect = (value: string) => {
    return;
  };

  useEffect(() => {

    if (!leadSelecionado?.id) return;
    const novosPredicates = Object.keys(checkboxFiltro);
    const predicates = novosPredicates?.filter((key) => checkboxFiltro[key]).map((valor) => {
      return {
        key: 'leadHistoricoTipo.id',
        value: valor,
      }
    })
    fetchHistoricos(predicates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxFiltro, leadSelecionado])

  const flTodosChecked = useMemo(() => {
    const novosPredicates = Object.keys(checkboxFiltro);
    const filtrosAtivos = novosPredicates?.filter((key) => checkboxFiltro[key]);
    return filtrosAtivos?.length <= 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxFiltro, leadSelecionado])

  const onFiltrarHistorico = (e) => {

    setCheckboxFiltro((state) => {
      state[e.target.value] = e.target.checked;
      return { ...state };
    });
  }

  return (
    <>
      <div className="mb-20 mt-20">
        <Box sx={styles.boxTabsComunication}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ sx: { backgroundColor: 'transparent' } }}
            sx={styles.tabs}
            key={1}
          >
            <Tab label="Histórico do Lead" {...a11yProps(0)} />
            {/* <Tab label="Histórico Marketing" {...a11yProps(1)} disabled /> */}
          </Tabs>
        </Box>
      </div>
      <div id="tab-comunicacao">
        <TabPanel value={value} index={0} >
          <div className="flex justify-between gap-20 mb-20">
            <div className="flex align-center gap-10">
              <RestoreIcon />
              <h1 id="sem-margin">Histórico do Lead</h1>
            </div>
            {/* <Select
              defaultValue="1"
              style={{ width: 200 }}
              onChange={handleChangeSelect}
              options={[
                { value: '1', label: 'Filtrar por período' }
              ]}
            /> */}
          </div>
          <div className="box-timeline">
            <div className="box-filtros flex flex-row align-start justify-content-between gap-20">
              <div className="w-5">
                <div className="circle-azul">
                  <Unicons.UilFilter color="var(--azul-happy)" size={40} />
                </div>
              </div>
              <div className="flex flex-wrap align-center justify-start flex-fill gap-10">
                <div className={`tag-novo flex flex-row justify-between align-center`}>
                  <Checkbox checked={flTodosChecked}
                    className={`check-novo`} onChange={(e) => onFiltrarHistorico(e)} />
                  <p>Todos</p>
                </div>
                {state?.leadHistoricoTipos?.map?.((item: ICrmbLeadHistoricoTipoCleanDTO) => {
                  return <div key={item?.cdTipoLeadHistorico} className={`tag-novo flex flex-row justify-between align-center`}>
                    <Checkbox value={item.id} checked={checkboxFiltro[item?.id]}
                      className={`check-novo`} onChange={(e) => onFiltrarHistorico(e)} />
                    <p>{item?.dsTipoLeadHistorico}</p>
                  </div>
                })}
              </div>
            </div>
            <div className="box-timelines mt-20">

              {historicosAgrupados?.map?.((item, index) => {
                const data = parse(item.data, 'yyyy-MM', new Date());
                return <>
                  <div key={index} className="box-title-timeline">
                    <h6 style={{ textTransform: 'capitalize' }}>{format(data, 'MMMM', { locale: ptBR })}/{format(data, 'yyyy')}</h6>
                  </div>
                  <Timeline key={index + 1} sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}>
                    {item?.leads?.map?.((item: ICrmbLeadHistoricoDTO, index2) => {
                      return <TimelineItem key={index2 + index + 1}>
                        <TimelineSeparator>
                          <TimelineConnector sx={{ bgcolor: '#E8EAF6' }} />
                          <TimelineDot sx={{ bgcolor: '#EBEFFC' }}>
                            <RestoreIcon sx={{ color: '#9FA8DA' }} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <div className="flex h-50 align-center justify-start">
                            <div className="circle-cinza">
                              <PersonIcon sx={{ color: 'var(--cinza-azulado-200)' }} />
                            </div>
                            <div>
                              <p className="text-lead-timeline">
                                {item.tipoLeadHistorico.cdTipoLeadHistorico === TipoHistoricoLead.CODIGO_ENVIO_EMAIL ?
                                  <ModalRenderHTML
                                    title={item?.tipoLeadHistorico?.dsTipoLeadHistorico}
                                    renderHtml={item?.dsHistorico}
                                    remetente={item?.usuario?.pessoa?.email}
                                    type="email"
                                  />
                                  : null}
                                {item.tipoLeadHistorico.cdTipoLeadHistorico === TipoHistoricoLead.CODIGO_ENVIO_WHATSAPP ?
                                  <ModalRenderHTML
                                    title={item?.tipoLeadHistorico?.dsTipoLeadHistorico}
                                    renderHtml={item?.dsHistorico}
                                    remetente={item?.usuario?.pessoa?.nome}
                                    type="whatsapp"
                                  />
                                  : null}
                                {item.tipoLeadHistorico.cdTipoLeadHistorico !== TipoHistoricoLead.CODIGO_ENVIO_EMAIL &&
                                  item.tipoLeadHistorico.cdTipoLeadHistorico !== TipoHistoricoLead.CODIGO_ENVIO_WHATSAPP ?
                                  <span dangerouslySetInnerHTML={{ __html: item?.dsHistorico }} />
                                  : null}
                              </p>
                              <p className="text-lead-timeline">{format(formatRemove3Horas(new Date(item?.dataCriacao)), "dd/MM 'às' HH:mm:ss")}</p>
                            </div>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    })}
                  </Timeline>
                </>
              })}

              {/* <div className="box-title-timeline">
                <h6>Junho/2023</h6>
              </div>
              <Timeline sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: '#E8EAF6' }} />
                    <TimelineDot sx={{ bgcolor: '#EBEFFC' }}>
                      <RestoreIcon sx={{ color: '#9FA8DA' }} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <div className="flex h-50 align-center justify-start">
                      <div className="circle-cinza">
                        <PersonIcon sx={{ color: 'var(--cinza-azulado-200)' }} />
                      </div>
                      <div>
                        <p className="text-lead-timeline">
                          <span className="text-title-timeline">João A. Silva</span> fez contato por whatsapp com
                          <span className="text-title-timeline"> (99) 99999-9999</span> assunto
                          <span className="text-title-timeline"> Envio da 1ª proposta</span>
                        </p>
                        <p className="text-lead-timeline"> 16/07 às 10:54</p>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </Timeline> */}
            </div>

            <div className="table-footer flex justify-end">
              <Pagination
                onChange={(event, page) => onHistoricoPageChange(event, page - 1)}
                page={historicoResponse?.data ? historicoResponse?.data.pageable.pageNumber + 1 : 0}
                count={historicoResponse?.data ? historicoResponse?.data.totalPages : 0} />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}></TabPanel>
      </div>
    </>
  )
}
