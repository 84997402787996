import http from '../helpers/http-common';
import { ICrmbLeadTarefaCleanDTO, ICrmbLeadTarefaDTO, ICrmbLeadTarefaFullDTO } from '../models/happy-code-api.model';
import { Predicate, formatHttpParams } from '../models/predicate.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';
import { baseUpdate } from './base/base-update.service';

export const useLeadTarefaService = () => {
	const resourceURL = 'crmb/lead-tarefa';

	const patchFullCustom = (body) => {
		return http.patch(`${resourceURL}/custom/${body.id}`, body);
	}

	const findCustom = (predicate = new Predicate()) => {
		return http.get(`${resourceURL}/custom`, formatHttpParams(predicate));
	}


	const findCustomList = (predicate = new Predicate()) => {
		return http.get(`${resourceURL}/custom/list`, formatHttpParams(predicate));
	}


	return {
		...baseCreate<ICrmbLeadTarefaCleanDTO, ICrmbLeadTarefaDTO, ICrmbLeadTarefaFullDTO>(resourceURL),
		...baseFindList<ICrmbLeadTarefaCleanDTO, ICrmbLeadTarefaDTO, ICrmbLeadTarefaFullDTO>(resourceURL),
		...baseUpdate<ICrmbLeadTarefaCleanDTO, ICrmbLeadTarefaDTO, ICrmbLeadTarefaFullDTO>(resourceURL),
		patchFullCustom,
		findCustom,
		findCustomList,
	};
};
