import { ICrmbLeadContatoCleanDTO, ICrmbLeadContatoDTO, ICrmbLeadContatoFullDTO} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';
import { baseUpdate } from './base/base-update.service';

export const useLeadContatoCampanhaService = () => {
	const resourceURL = 'crmb/lead-contato';

	return {
		...baseFindList<ICrmbLeadContatoCleanDTO, ICrmbLeadContatoDTO, ICrmbLeadContatoFullDTO>(resourceURL),
		...baseCreate<ICrmbLeadContatoCleanDTO, ICrmbLeadContatoDTO, ICrmbLeadContatoFullDTO>(resourceURL),
		...baseUpdate<ICrmbLeadContatoCleanDTO, ICrmbLeadContatoDTO, ICrmbLeadContatoFullDTO>(resourceURL),
	};
};
