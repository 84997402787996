import {ICrmbLeadOrigemCleanDTO, ICrmbLeadOrigemDTO, ICrmbLeadOrigemFullDTO
} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadOrigemService = () => {
	const resourceURL = 'crmb/lead-origem';

	return {
		...baseFindList<ICrmbLeadOrigemCleanDTO, ICrmbLeadOrigemDTO, ICrmbLeadOrigemFullDTO>(resourceURL),
	};
};
