import {ICrmbLeadTarefaTipoCleanDTO, ICrmbLeadTarefaTipoDTO, ICrmbLeadTarefaTipoFullDTO } from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTarefaTipoService = () => {
	const resourceURL = 'crmb/lead-tarefa-tipo';

	return {
		...baseFindList<ICrmbLeadTarefaTipoCleanDTO, ICrmbLeadTarefaTipoDTO, ICrmbLeadTarefaTipoFullDTO>(resourceURL),
	};
};
