import http from '../helpers/http-common';

import {ICrmbLeadInteresseCleanDTO, ICrmbLeadInteresseDTO, ICrmbLeadInteresseFullDTO} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';
import { baseUpdate } from './base/base-update.service';

export const useLeadInteresseService = () => {
	const resourceURL = 'crmb/lead-interesse';

	const getUltimoInteresseDoLead = (idLead: number) => {
		return http.get(`${resourceURL}/${idLead}/ultimo`);
	}

	return {
		...baseCreate<ICrmbLeadInteresseCleanDTO, ICrmbLeadInteresseDTO, ICrmbLeadInteresseFullDTO>(resourceURL),
		...baseUpdate<ICrmbLeadInteresseCleanDTO, ICrmbLeadInteresseDTO, ICrmbLeadInteresseFullDTO>(resourceURL),
		...baseFindList<ICrmbLeadInteresseCleanDTO, ICrmbLeadInteresseDTO, ICrmbLeadInteresseFullDTO>(resourceURL),
		getUltimoInteresseDoLead,
	};
};
