import { ICrmbLeadAnotacaoCleanDTO, ICrmbLeadAnotacaoDTO, ICrmbLeadAnotacaoFullDTO } from "../models/happy-code-api.model";
import { baseCreate } from "./base/base-create.service";

export const useLeadAnotacaoService = () => {

  const resourceURL = 'crmb/lead-anotacao';

  return {
    ...baseCreate<ICrmbLeadAnotacaoCleanDTO, ICrmbLeadAnotacaoDTO, ICrmbLeadAnotacaoFullDTO>(resourceURL),
  };

}
