import {ICrmbLeadHistoricoTipoCleanDTO, ICrmbLeadHistoricoTipoDTO, ICrmbLeadHistoricoTipoFullDTO} from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadHistoricoTipoService = () => {
	const resourceURL = 'crmb/tipo-lead-historico';

	return {
		...baseFindList<ICrmbLeadHistoricoTipoCleanDTO, ICrmbLeadHistoricoTipoDTO, ICrmbLeadHistoricoTipoFullDTO>(resourceURL),
	};
};
