import { AxiosResponse } from 'axios';
import http from '../helpers/http-common';
import {ICrmbLeadEmailCleanDTO, ICrmbLeadEmailCustomDTO, ICrmbLeadEmailDTO, ICrmbLeadEmailFullDTO} from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useEmailService = () => {
	const resourceURL = '/crmb/lead-email';

	const enviarEmail = (dto: ICrmbLeadEmailCustomDTO): Promise<AxiosResponse<ICrmbLeadEmailCustomDTO>> => {
		return http.post(`${resourceURL}/enviar-email`, dto);
	}

	return {
		...baseFind<ICrmbLeadEmailCleanDTO, ICrmbLeadEmailDTO, ICrmbLeadEmailFullDTO>(resourceURL),
		...baseFindList<ICrmbLeadEmailCleanDTO, ICrmbLeadEmailDTO, ICrmbLeadEmailFullDTO>(resourceURL),
		...baseUpdate<ICrmbLeadEmailCleanDTO, ICrmbLeadEmailDTO, ICrmbLeadEmailFullDTO>(resourceURL),
		...baseCreate<ICrmbLeadEmailCleanDTO, ICrmbLeadEmailDTO, ICrmbLeadEmailFullDTO>(resourceURL),
		...baseFindById<ICrmbLeadEmailCleanDTO, ICrmbLeadEmailDTO, ICrmbLeadEmailFullDTO>(resourceURL),
		enviarEmail,
	};
};