import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import { format } from 'date-fns';
import { useState } from 'react';
import { useGlobalContext } from '../../context/global.service';
import { ICrmbLeadTarefaCustomCleanDTO } from '../../models/happy-code-api.model';
import { ModalAdiarTarefa } from '../Modal/adiar-tarefa';
import { ModalDatetimePicker } from '../Modal/datetime-picker';
import { ModalResposta } from '../Modal/resposta';
import { useLeadTarefaService } from '../../services/lead-tarefa.service';
import { useNotificationService } from '../../services/notification.service';
import { useLeadContext } from '../../context/lead.context.service';

interface Props {
  status: string;
  proximaTarefa?: ICrmbLeadTarefaCustomCleanDTO;
}

export const NotificationLead = ({ status, proximaTarefa }: Props) => {

  const { setIsGlobalLoading } = useGlobalContext();
  const { setFlModoEdicaoTarefa, refreshLeadSelecionado, refreshLeads } = useLeadContext();
  const [flDateTimePicker, setFlDateTimePicker] = useState<boolean>();
  const [flModalMarcarConcluido, setFlModalMarcarConcluido] = useState<boolean>();
  const [flModalAdiarTarefa, setFlModalAdiarTarefa] = useState<boolean>();

  const tarefaService = useLeadTarefaService();

  const notification = useNotificationService();

  const onConcluirTarefa = () => {
    setIsGlobalLoading(true);
    tarefaService.patch({
      id: proximaTarefa.id,
      flConcluido: true,
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          refreshLeadSelecionado();
          refreshLeads();
          notification({ description: 'Tarefa marcada como concluída.', type: 'success' });
          return;
        }

        throw new Error();
      })
      .catch((e) => {
        notification({ description: 'Não foi possível marcar como concluído.', type: 'error' });
      })
      .finally(() => setIsGlobalLoading(false))

    setFlModalMarcarConcluido(false);
  }

  return (
    <div className={`tarefa-panel-${status || 'futuro'} flex align-center justify-between`}>
      <div className="flex align-center justify-start gap-20">
        <InfoOutlinedIcon sx={{ color: 'var(--white)' }} />
        <h5>Próxima Tarefa</h5>
        <p>{proximaTarefa?.dsTipoTarefa}: {proximaTarefa?.dhTarefa ? (`Dia
          ${' ' + format(new Date(proximaTarefa?.dhTarefa), 'dd/MM/yyyy')} às
          ${' ' + format(new Date(proximaTarefa?.hrTarefa), 'HH:mm')}`) : 'sem data definida'}</p>
      </div>
      <div className="flex align-center justify-end gap-10">
        <EditIcon style={{ cursor: 'pointer' }} sx={{ color: 'var(--white)' }} onClick={() => setFlModoEdicaoTarefa(true)} />
        <RestoreIcon style={{ cursor: 'pointer' }} sx={{ color: 'var(--white)' }} onClick={() => setFlModalAdiarTarefa(true)} />
        <CheckCircleIcon style={{ cursor: 'pointer' }} sx={{ color: 'var(--white)' }} onClick={() => setFlModalMarcarConcluido(true)} />
      </div>

      <ModalAdiarTarefa
        open={flModalAdiarTarefa}
        onClose={() => setFlModalAdiarTarefa(false)}
        onAbrirDatetimePicker={() => setFlDateTimePicker(true)}
        tarefaSelecionada={proximaTarefa}
      />
      <ModalDatetimePicker
        open={flDateTimePicker}
        onCancel={() => setFlDateTimePicker(false)}
        onEscolherData={() => {
          setFlModalAdiarTarefa(false);
          setFlDateTimePicker(false);
          refreshLeadSelecionado();
        }}
        tarefaSelecionada={proximaTarefa}
      />
      <ModalResposta
        text='Deseja concluir a tarefa?'
        open={flModalMarcarConcluido}
        onClose={onConcluirTarefa}
        onCancel={() => {
          setFlModalMarcarConcluido(false)
        }}
        status='A tarefa será concluída'
      />
    </div>
  )
}
