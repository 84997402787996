import {ICrmbLeadTarefaResponsavelCleanDTO, ICrmbLeadTarefaResponsavelDTO, ICrmbLeadTarefaResponsavelFullDTO } from '../models/happy-code-api.model';
import { baseCreate } from './base/base-create.service';
import { baseFindList } from './base/base-find-list.service';

export const useLeadTarefaResponsavelService = () => {
	const resourceURL = 'crmb/lead-tarefa-responsavel';

	return {
		...baseCreate<ICrmbLeadTarefaResponsavelCleanDTO, ICrmbLeadTarefaResponsavelDTO, ICrmbLeadTarefaResponsavelFullDTO>(resourceURL),
		...baseFindList<ICrmbLeadTarefaResponsavelCleanDTO, ICrmbLeadTarefaResponsavelDTO, ICrmbLeadTarefaResponsavelFullDTO>(resourceURL),
	};
};
