import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Pagination } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Button } from 'antd';
import { format } from 'date-fns';
import { MouseEvent, useState } from 'react';
import { Itemlista } from "../../components/Item/lista";
import { ModalMudarStatus } from '../../components/Modal/mudar-estagio';
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmbLeadCustomDTO } from '../../models/happy-code-api.model';
import { useLeadService } from '../../services/lead.service';
import { useNotificationService } from '../../services/notification.service';
import { getStatusLead } from '../../util/lead.util';
export const List = () => {

  const [isOpenHeader, setIsOpenHeader] = useState('d-none');
  const [isAllCheckboxSelect, setIsAllCheckboxSelect] = useState(false);
  const { state, setState, leadResponse, onPageChange, refreshLeads } = useLeadContext();
  const {  setIsGlobalLoading } = useGlobalContext();
  const [leadsSelecionados, setLeadsSelecionados] = useState<ICrmbLeadCustomDTO[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [flArquivar, setFlArquivar] = useState<boolean>(false);
  const [flModalMudarStatus, setFlModalMudarStatus] = useState<boolean>(false);
  const notification = useNotificationService();

  const leadService = useLeadService();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  function handleAllCheckbox(e: any) {

    if (e.target.checked) {
      setIsAllCheckboxSelect(true);
      setCheckAll(true);
      setLeadsSelecionados([...leadResponse?.data?.content as any]);
      setIsOpenHeader('header-list')
    } else {
      setCheckAll(false);
      setIsAllCheckboxSelect(false);
      setLeadsSelecionados(JSON.parse(JSON.stringify([])));
      setIsOpenHeader('d-none')

    }
  }

  function handleAction(event: any) {
    try {
      const id = parseInt(event.target.value);

      const leads = (leadResponse?.data?.content as ICrmbLeadCustomDTO[]);
      const leadParaRemoverOuAdicionar = leads?.find(item => item.id === id);

      const leadJaSelecionado = (leadsSelecionados || [])?.find(item => item.id === id);

      let novosLeads = [...(leadsSelecionados || [])];

      if (leadJaSelecionado) {
        novosLeads = (leadsSelecionados || [])?.filter(item => item.id !== id);
      } else {
        novosLeads.push(leadParaRemoverOuAdicionar)
      }

      setLeadsSelecionados(novosLeads);

      if (novosLeads?.length === leadResponse?.data?.content?.length) {
        setIsAllCheckboxSelect(true);
        setCheckAll(true);
      } else {
        setIsAllCheckboxSelect(false);
        setCheckAll(false);
      }

      // if (isOpenHeader === 'd-none') {
      //   setIsOpenHeader('header-list')
      // } else {
      //   setIsOpenHeader('d-none')
      // }

      if (novosLeads?.length > 0) {
        setIsOpenHeader('header-list')
      } else {
        setIsOpenHeader('d-none')
      }
    } catch (e) {
      return;
    }
  }

  const onMudarStatusPress = () => {
    setFlModalMudarStatus(true);
  }

  const onArquivarClick = () => {
    setFlArquivar(true);
  }

  const onDescartarLead = () => {
    setFlArquivar(false);

    const promises = leadsSelecionados?.map((lead) => leadService.alterarStatus(lead.id, 'DES'));
    setIsGlobalLoading(true);
    Promise.all(promises).then(() => {
      return refreshLeads();
    }).then(() => {
      notification({ description: 'Lead(s) descartado(s)!', type: 'success' });
      refreshLeads();
    }).catch((e) => {
      notification({ description: 'Ocorreu um erro ao descartar o(s) lead(s)', type: 'error' });
    }).finally(() => setIsGlobalLoading(false))
  }

  return (
    <div className="area area-list" style={{
      background: 'white',
    }}>
      <div className="body-area area-table">
        <div className={`${isOpenHeader} flex flex-row align-center justify-between`}>
          <p>{leadsSelecionados?.length} item selecionado</p>
          <div className="flex flex-row justify-center align-center gap-10">
            {isAllCheckboxSelect === true ?
              <Button className="button-white" onClick={onArquivarClick}>Arquivar</Button>
              :
              <></>
            }
            {/* <Button className="button-white" onClick={onMudarStatusPress}>Mudar Status</Button> */}

            <Button
              onClick={onMudarStatusPress}
              className="button-white"
              icon={<SwapHorizIcon className="mr-10" />}>
              Mover para etapa
            </Button>
            {/* {(isOpenHeader === 'header-list' && isAllCheckboxSelect === false) ?
              <Button className="button-white" icon={<EditIcon className="mr-10" />}>
                Editar lead
              </Button>
              :
              <></>
            } */}
          </div>
        </div>
        <div className="table table-list flex">
          <div style={{ backgroundColor: '#0C306C' }} className="table-header flex flex-row justify-start align-center">
            <div style={{ width: 100 }} className="table-item flex justify-center align-center gap-10">
              <Checkbox onChange={handleAllCheckbox} checked={checkAll} />
              <NoteAddIcon style={{ color: 'var(--white)' }} />
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Nome do Lead</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Status</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Etapa</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Qualificação</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Criação</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Responsável</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Quant. de Interações</span>
            </div>
            <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Última Interação</span>
            </div>
            {/* <div style={{ width: 250 }} className="table-item flex align-center">
              <span>Detalhes</span>
            </div> */}
          </div>
          <div className="table-items">
            {leadResponse?.data?.content.map((lead: ICrmbLeadCustomDTO, index: number) => {
              const status = getStatusLead(lead?.proximaTarefa);
              const leadJaSelecionado = (leadsSelecionados || [])?.find?.(item => item.id === lead?.id);


              // edeff1
              return (<div key={lead.id} style={{ background: index % 2 === 0 ? '#edeff1' : 'white' }}>
                <Itemlista
                  id={lead.id}
                  key={lead.id}
                  etapa={lead?.estagio?.dsLeadEstagio}
                  cliente={lead.dsNomeLead}
                  status={status}
                  checked={leadJaSelecionado?.id > 0}
                  dataInicio={format(new Date(lead.dataCriacao), 'dd/MM/yyyy')}
                  note={lead.dsUltimaAnotacao?.length > 0}
                  dsNote={lead.dsUltimaAnotacao}
                  qualify={lead.nrAvaliacaoLead}
                  cdStatus={lead.estagio.cdLeadEstagio}
                  supervisor={'--'}
                  iteracoes={lead.nrInteracao || 0}
                  ultimaIteracao={lead.dataAtualizacao ? format(new Date(lead.dataAtualizacao), 'dd/MM/yyyy') : '--'}
                  onAction={handleAction}
                />
              </div>
              )
            })}
          </div>
          <div className="table-footer flex flex-end">
            <Pagination
              onChange={(event, page) => onPageChange(event, page - 1)}
              page={leadResponse?.data ? leadResponse?.data.pageable.pageNumber + 1 : 0}
              count={leadResponse?.data ? leadResponse?.data.totalPages : 0} />

          </div>
        </div>
      </div>


      <ModalMudarStatus
        open={flModalMudarStatus}
        onClose={() => {
          setFlModalMudarStatus(false)
        }}
        leads={leadsSelecionados}
      />

      <ModalResposta
        open={flArquivar}
        onClose={() => {
          onDescartarLead()
        }}
        status='Deseja descartar o lead?'
        text='O lead será movido para "descartado"'
        onCancel={() => {
          setFlArquivar(false);
        }}
      />
    </div >
  )
}
