import { Rating } from "@mui/material";
import { Button, Input, Select } from 'antd';
import { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { ModalResposta } from '../../components/Modal/resposta';
import { useLeadContext } from "../../context/lead.context.service";
import { ICrmbLeadCampanhaCleanDTO, ICrmbLeadOrigemCleanDTO, ICurriculoCleanDTO } from "../../models/happy-code-api.model";
import { Predicate } from "../../models/predicate.model";

import { useLeadCampanhaService } from "../../services/lead-campanha.service";
import { useLeadOrigemService } from "../../services/lead-origem.service";
import { useNotificationService } from "../../services/notification.service";
import { useCurriculoService } from "../../services/curriculo.service";

interface Props {
    open: boolean;
    onClose: () => void;
}

export const ModalFilter = (props: Props) => {
    const { state, setState, refresh } = useLeadContext();
    const leadOrigemService = useLeadOrigemService();
    const curriculoService = useCurriculoService();
    const leadCampanhaService = useLeadCampanhaService();
    const notification = useNotificationService();
    const [leadOrigens, setLeadOrigens] = useState<ICrmbLeadOrigemCleanDTO[]>();
    const [curriculos, setCurriculos] = useState<ICurriculoCleanDTO[]>();
    const [campanhas, setCampanhas] = useState<ICrmbLeadCampanhaCleanDTO[]>();

    const { open, onClose } = props;
    const { Option } = Select;

    const fetch = () => {
        const predicate = new Predicate();
        predicate.addOption('ativo', true);

        leadOrigemService.findCleanList(predicate).then((response) => {
            setLeadOrigens(response?.data);
        });
    }
    const findProdutos = () => {
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        return curriculoService.findCleanList(predicate)
            .then((response) => {
                setCurriculos(response?.data);
            });
    }

    const findCampanha = () => {
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        return leadCampanhaService.findCleanList()
            .then(response => {
                setCampanhas(response.data)
            });
    }

    useEffect(() => {
        findProdutos();
        findCampanha();
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const onAplicarFiltros = () => {
        refresh().then(() => {
            onClose();
            notification({ description: 'Filtros aplicados com sucesso', type: 'success' })
        });
    }

    const onFecharModalFiltrosAplicados = () => {
        setIsOpen1(false)
    }

    const onLimparFiltros = () => {
        const isAberto = isOpen2;
        isAberto ? setIsOpen2(false) : setIsOpen2(true);

        setState((state) => ({
            ...state, filtro: {
                ...state?.filtro,
                flFiltrar: true,
                leadOrigemParaFiltrar: null,
                tipoDataParaFiltrar: "data_retorno",
                avaliacaoParaFiltrar: null,
                leadDataFimParaFiltrar: null,
                leadDataInicioParaFiltrar: null,
                campanha: null,
                produto: null,
            }
        }));
    }


    const onLimparFiltrosClick = () => {
        const isAberto = isOpen2;
        isAberto ? setIsOpen2(false) : setIsOpen2(true);
    }

    const handleChangeOrigem = (value) => {
        const origem = leadOrigens?.find?.((item) => item?.id === value);
        setState((state) => ({
            ...state, filtro: {
                ...state?.filtro,
                leadOrigemParaFiltrar: origem,
            }
        }))

    }

    if (!open) return null

    return (
        <>
            <div className="flex flex-row justify-center align-center modal-container">
                <div className="flex flex-column justify-start align-start modal">
                    <Button onClick={onClose} className="button-x">x</Button>
                    <h3 >Filtrar resultados</h3>

                    <div className="text-over-input">
                        <label>Origem</label>
                    </div>
                    <div className="input-modal mb-10">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecione a origem"
                            bordered={false}
                            value={state?.filtro?.leadOrigemParaFiltrar?.id}
                            onChange={handleChangeOrigem}>
                            {leadOrigens?.map?.((item) =>
                                <Option key={item?.id} value={item?.id}>{item?.dsLeadOrigem}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="text-over-input">
                        <label>Filtro por</label>
                    </div>
                    <div className="input-modal mb-10">
                        <Select style={{ width: '100%' }}
                            value={state?.filtro?.tipoDataParaFiltrar}
                            bordered={false} defaultValue="data_retorno" onChange={(e) => {
                                setState((state) => ({
                                    ...state, filtro: {
                                        ...state?.filtro,
                                        tipoDataParaFiltrar: e,
                                    }
                                }))
                            }}>
                            <Option value="data_retorno">Data de retorno</Option>
                            <Option value="data_criacao">Data de criação</Option>
                        </Select>
                    </div>
                    <div className="text-over-input">
                        <label>Filtrar por currículo</label>
                    </div>
                    <div className="input-modal mb-10">
                        <Select
                            style={{ width: '100%' }}
                            bordered={false}
                            placeholder="Selecione o currículo"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            value={state?.filtro?.produto}
                            onChange={(e) => {
                                setState({
                                    ...state, filtro: {
                                        ...state?.filtro,
                                        produto: e,
                                    }
                                });
                            }}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {curriculos && curriculos.map((item) =>
                                <Option key={item?.id} value={item?.id} text={item?.descricaoCurriculo}>{item?.descricaoCurriculo}</Option>
                            )}
                        </Select>
                    </div>
                    <div className="text-over-input">
                        <label>Filtrar por campanha</label>
                    </div>
                    <div className="input-modal mb-10">
                        <Select
                            style={{ width: '100%' }}
                            bordered={false}
                            placeholder="Selecione a campanha"
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            value={state?.filtro?.campanha}
                            onChange={(e) => {
                                setState({
                                    ...state, filtro: {
                                        ...state?.filtro,
                                        campanha: e,
                                    }
                                });
                            }}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {campanhas && campanhas.map?.((item) =>
                                <Option key={item?.id} value={item?.id} text={item?.dsLeadCampanha}>{item?.dsLeadCampanha}</Option>
                            )}
                        </Select>
                    </div>
                    <div className="flex flex-row">
                        <div className="w-50" style={{ marginRight: 5 }}>
                            <div className="text-over-input">
                                <label>Data de início</label>
                            </div>
                            <div className="input-modal mb-10">
                                <InputMask
                                    mask='99/99/9999'
                                    maskPlaceholder="dd/mm/yy"
                                    value={state?.filtro?.leadDataInicioParaFiltrar ?? ""}
                                    onChange={(e) => {
                                        setState((state) => ({
                                            ...state, filtro: {
                                                ...state?.filtro,
                                                leadDataInicioParaFiltrar: e?.target?.value,
                                            }
                                        }))
                                    }}
                                >
                                    {(inputProps) => <Input {...inputProps} placeholder='dd/mm/aaaa' bordered={false} />}
                                </InputMask>
                            </div>


                        </div>
                        <div className="w-50" style={{ marginLeft: 5 }}>
                            <div className="text-over-input">
                                <label>Data de fim</label>
                            </div>
                            <div className="input-modal mb-10">
                                <InputMask
                                    mask='99/99/9999'
                                    maskPlaceholder="dd/mm/yy"
                                    value={state?.filtro?.leadDataFimParaFiltrar ?? ""}
                                    onChange={(e) => {
                                        setState((state) => ({
                                            ...state, filtro: {
                                                ...state?.filtro,
                                                leadDataFimParaFiltrar: e?.target?.value,
                                            }
                                        }))
                                    }}
                                >
                                    {(inputProps) => <Input {...inputProps} placeholder='dd/mm/aaaa' bordered={false} />}
                                </InputMask>
                            </div>
                        </div>
                    </div>
                    <h6 className="mt-5 mb-10">Filtro por avaliação</h6>
                    <div className="flex flex-row align-center">
                        <h5 className="mr-10">Todas</h5>
                        <div className="icons flex flex-row justify-start align-center w-100">
                            <Rating
                                name="simple-controlled"
                                value={state?.filtro?.avaliacaoParaFiltrar ?? 0}
                                onChange={(event, newValueRating) => {
                                    setState((state) => ({
                                        ...state, filtro: {
                                            ...state?.filtro,
                                            avaliacaoParaFiltrar: newValueRating,
                                        }
                                    }))
                                }}
                                sx={{
                                    '& .MuiRating-iconFilled': { color: 'var(--azul-happy)' },
                                    '& .MuiRating-iconHover': { color: 'var(--azul-happy)' }
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-end w-100 mt-30">
                        <Button onClick={onLimparFiltrosClick} className="button-blue mr-5">Limpar Filtros</Button>
                        <Button onClick={onAplicarFiltros} className="button-primary">Aplicar Filtros</Button>
                    </div>
                </div>
                <ModalResposta
                    open={isOpen1}
                    onClose={onFecharModalFiltrosAplicados}
                    status="Filtrar resultados"
                    text="Os filtros foram aplicados com sucesso!"
                />
                <ModalResposta
                    open={isOpen2}
                    onClose={onLimparFiltros}
                    status="Limpar filtros"
                    text="Os filtros aplicados foram removidos com êxito."
                />
            </div >
        </>
    )
}
