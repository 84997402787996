import { Button, Checkbox, Input, Select, notification } from 'antd';
import { useMemo, useState } from "react";
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from "../../context/global.service";
import { useLeadContext } from "../../context/lead.context.service";
import { IEnderecoCleanDTO } from '../../models/happy-code-api.model';
import { useLeadService } from "../../services/lead.service";
import { validateEmail } from '../../util/format';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const ModalLeadNew = (props: Props) => {
    const defaultValue = {
        dsNomeLead: null,
        cdEmail: null,
        cdDddCelular: null,
        cdCelular: null,
        origem: null,
        campanha: null,
        flProjeto3k: null,
        endereco: {} as IEnderecoCleanDTO,
    }

    const { open, onClose } = props;
    const { Option } = Select;
    const { setIsGlobalLoading } = useGlobalContext();
    const { state, unidade, refresh } = useLeadContext();
    const [body, setBody] = useState(defaultValue);

    const leadService = useLeadService();

    const emailValido = useMemo(() => {
        if (body?.cdEmail === null || body?.cdEmail === undefined) return false;

        return validateEmail(body?.cdEmail);
    }, [body?.cdEmail])

    function handleChange(value: any) {
        return;
    }

    const [isOpen, setIsOpen] = useState(false);

    const onChangeModal = () => {
        if (isOpen === true) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

    const onSalvarClick = () => {
        const novoBody = {
            ...body,
            origem: body.origem ? { id: body.origem } : null,
            campanha: body.campanha ? { id: body.campanha } : null,
            estagio: state?.boards?.[0]?.id ? { id: state?.boards?.[0]?.id } : null,
            unidade: unidade.id ? { id: unidade.id } : null,
            tipo: {
                id: 1,
            },
            endereco: body?.endereco?.id ? body?.endereco : null,
        }

        setIsGlobalLoading(true);

        return leadService.create(novoBody).then((response) => {
            if (response?.status >= 200 && response?.status <= 299) {
                return refresh();
            }

            throw new Error('Falha ao cadastrar o lead.');
        }).then((_) => {
            notification.success({ description: 'Lead cadastrado com sucesso!', type: 'success', message: 'Sucesso!', placement: 'bottomRight' });
            onClose();
            setBody({ ...defaultValue })
        }).finally(() => {
            setIsGlobalLoading(false)
        })

    }

    const onClose1 = () => {
        setIsOpen(false)
    }


    const submitValidationFields = () => {
        if (!body?.dsNomeLead) {
            document.getElementById('dsNomeLead')?.focus();
            return notification.warning({
                description: `Verifique o campo nome do lead. É necessário informar um nome para o Lead.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        if (!body?.cdEmail) {
            document.getElementById('cdEmail')?.focus();
            return notification.warning({
                description: `Verifique o campo e-mail. É necessário informar o e-mail.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        if (!body?.cdDddCelular && !body?.cdCelular) {
            document.getElementById('cdEmail')?.focus();
            return notification.warning({
                description: `Verifique os campos DDD e celular. É necessário informar o DDD e celular.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        if (!body?.origem) {
            document.getElementById('origem')?.focus();
            return notification.warning({
                description: `Verifique o campo da origem. É necessário informar o origem.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        if (body?.origem === 2 && !body?.campanha) {
            document.getElementById('campanha')?.focus();
            return notification.warning({
                description: `Verifique o campo da campanha. É necessário informar a campanha.`,
                type: 'warning',
                message: 'Atenção!',
                placement: 'bottomRight'
            });
        }
        onSalvarClick();
    }

    if (!open) return null

    return (
        <>
            <div className="flex flex-row justify-center align-center modal-container">
                <div className="flex flex-column justify-start align-start modal">
                    <Button onClick={onClose} className="button-x">x</Button>
                    <h3 >Cadastrar novo lead</h3>
                    <div className="text-over-input">
                        <label htmlFor="dsNomeLead">Nome</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Input id="dsNomeLead" placeholder='Nome' bordered={false} onChange={(e) => setBody((body) => ({ ...body, dsNomeLead: e.target.value }))} />
                    </div>
                    <div className="text-over-input">
                        <label htmlFor="cdEmail">Email</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Input id="cdEmail" placeholder='Email' bordered={false} onChange={(e) => setBody((body) => ({ ...body, cdEmail: e.target.value }))} />
                    </div>
                    {!emailValido && body?.cdEmail?.length >= 0 && <small>Digite um e-mail válido</small>}


                    <div className="flex flex-row">
                        <div className="w-25 mr-5">
                            <div className="text-over-input">
                                <label htmlFor="cdDddCelular">DDD</label>
                            </div>
                            <div className="flex flex-row">
                                <div className="input-modal mb-20">
                                    <div>
                                        <Input
                                            id="cdDddCelular"
                                            style={{ width: "100%" }}
                                            type="tel"
                                            placeholder='DDD'
                                            bordered={false}
                                            maxLength={2}
                                            onChange={(e) => setBody((body) => ({ ...body, cdDddCelular: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-75">
                            <div className="text-over-input">
                                <label htmlFor="cdCelular">Celular</label>
                            </div>
                            <div className="flex flex-row">
                                <div className="input-modal mb-20">
                                    <Input
                                        id="cdCelular"
                                        maxLength={9}
                                        placeholder='Celular'
                                        bordered={false}
                                        onChange={(e) => setBody((body) => ({ ...body, cdCelular: e.target.value }))} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-over-input">
                        <label htmlFor="origem">Selecione uma Origem</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id="origem"
                            style={{ width: '100%' }}
                            placeholder={"Selecione"}
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue="todos"
                            value={body?.origem ?? "todos"}
                            onChange={(e) => setBody((body) => ({ ...body, origem: e }))}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {state?.leadOrigens?.map?.((item) =>
                                <Option key={item?.id} value={item?.id} text={item?.dsLeadOrigem}>{item?.dsLeadOrigem}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="text-over-input">
                        <label htmlFor="campanha">Campanha</label>
                    </div>
                    <div className="input-modal mb-20">
                        <Select
                            id="campanha"
                            style={{ width: '100%' }}
                            placeholder={"Selecione"}
                            bordered={false}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            defaultValue="todos"
                            value={body?.campanha ?? "todos"}
                            onChange={(e) => setBody((body) => ({ ...body, campanha: e }))}
                            filterOption={(input, option) =>
                                option && option?.text?.toLowerCase().includes(input.toLowerCase())
                            }>
                            {state?.campanhas?.map?.((item) =>
                                <Option key={item?.id} value={item?.id} text={item?.dsLeadCampanha}>{item?.dsLeadCampanha}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="flex flex-row">
                        <div
                            className="input-modal flex-column justify-center mb-20"
                            style={{ maxWidth: "5%", minWidth: "5%", maxHeight: "5%", height: "10%" }}>
                            <Checkbox id="flProjeto3k" onChange={(e) => setBody({ ...body, flProjeto3k: e.target.checked })} />
                        </div>
                        <div className="text-over-input flex-column" style={{ left: 10 }}>
                            <label htmlFor="flProjeto3k">Lead projeto 3k?</label>
                        </div>
                    </div>

                    <div className="flex flex-row justify-end w-100">
                        <Button onClick={onClose} className="button-blue mr-5">Cancelar</Button>
                        <Button onClick={submitValidationFields} className="button-primary">Salvar</Button>
                    </div>
                </div>
                <ModalResposta
                    open={isOpen}
                    onClose={onClose1}
                    status="Sucesso!"
                    text="As informações do Lead foram salvas com sucesso."
                    link=""
                />
            </div>
        </>
    )
}
