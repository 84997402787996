import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, Input, InputNumber, Select } from "antd";
import { useEffect, useMemo, useState } from 'react';
import { ModalResposta } from '../../components/Modal/resposta';
import { useGlobalContext } from '../../context/global.service';
import { useLeadContext } from '../../context/lead.context.service';
import { ICrmbLeadInteresseDTO, ICurriculoCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useCurriculoService } from '../../services/curriculo.service';
import { useLeadInteresseService } from '../../services/lead-interesse.service';
import { useNotificationService } from '../../services/notification.service';
import { currencyFormatter, currencyParser } from '../../util/format';

export const ProdutosLead = () => {

  const [curriculos, setCurriculos] = useState<ICurriculoCleanDTO[]>();
  const [interesses, setInteresses] = useState<ICrmbLeadInteresseDTO[]>();
  const curriculoService = useCurriculoService();
  const { setIsGlobalLoading } = useGlobalContext();
  const { leadSelecionado } = useLeadContext();

  const [idCurriculoSelecionado, setIdCurriculoSelecionado] = useState<number>();
  const [valorSelecionado, setValorSelecionado] = useState(0.00);
  const [dsObservacao, setDsObservacao] = useState<string>();
  const leadInteresseService = useLeadInteresseService();
  const notification = useNotificationService();
  const [openModalAddProduto, setOpenModalAddProduto] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const curriculoSelecionado = useMemo(() => {
    if (!idCurriculoSelecionado || curriculos?.length <= 0) return;

    return curriculos.find((item) => item.id === idCurriculoSelecionado);
  }, [idCurriculoSelecionado, curriculos])


  const fetchInteresses = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);
    predicate.addOption('lead.id', leadSelecionado.id);
    predicate.sort = 'desc';

    return leadInteresseService.findList(predicate).then((response) => {
      if (response.status === 200) {
        setInteresses(response.data);
      }
      return response;
    })
  }

  const fetchData = () => {
    const predicate = new Predicate();
    predicate.addOption('ativo', true);
    setIsGlobalLoading(true);

    fetchInteresses().then((response) => {
      if (response.status === 200) {
        setInteresses(response.data);
      }

      return curriculoService.findCleanList(predicate)
        .then((response) => {
          if (response.status === 200) {
            setCurriculos(response?.data);
          }
        })
    })
      .finally(() => setIsGlobalLoading(false))
  }


  const handleChange = (value: number) => {
    setIdCurriculoSelecionado(value);
  }

  const validarForm = () => {
    if (!curriculoSelecionado?.id) {
      notification({ description: 'Preencha todos os campos', type: 'warning' })
      return false;
    }

    if (!leadSelecionado?.id) {
      notification({ description: 'Por favor, selecione o lead novamente', type: 'warning' })
      return false;
    }

    return true;
  }

  const onAdicionarInteresse = () => {
    const body = {
      curriculo: {
        id: curriculoSelecionado?.id,
      },
      vlLeadInteresse: valorSelecionado,
      lead: {
        id: leadSelecionado.id,
      },
      dsObservacao,
    }
    setIsGlobalLoading(true);

    leadInteresseService.create(body).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        fetchInteresses();
        notification({ description: 'Produto cadastrado!', type: 'success' });
        setDsObservacao(null);
        setValorSelecionado(0.00);
        setIdCurriculoSelecionado(null);
        setOpenModalAddProduto(false);
        return;
      }

      throw new Error();
    }).catch(() => {
      notification({ description: 'Ocorreu um erro ao cadastrar o produto', type: 'error' });
    }).finally(() => {
      setIsGlobalLoading(false);
    })
  }

  const onDeleteClick = (item: ICrmbLeadInteresseDTO) => {
    const body = {
      id: item.id,
      ativo: false,
    };

    setIsGlobalLoading(true);

    leadInteresseService
      .patch(body)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          fetchInteresses();
          notification({ description: 'Produto removido!', type: 'success' });
          return;
        }

        throw new Error();
      })
      .catch(() => {
        notification({
          description: 'Ocorreu um erro ao remover o produto',
          type: 'error',
        });
      })
      .finally(() => {
        setIsGlobalLoading(false);
      });
  };

  const handleOpenModalAddProduto = () => {
    if (validarForm()) {
      setOpenModalAddProduto(true);
    }
  }

  return (
    <div className="tarefa-panel">
      <div className="flex justify-between gap-20 mb-20">
        <div className="flex align-center gap-10">
          <AddShoppingCartIcon />
          <h1 id="sem-margin">Adicionar produtos e serviços</h1>
        </div>
      </div>
      <div className="box-documentos mb-20 shadow-lead" style={{ background: 'white' }}>
        <form action="" className="flex flex-wrap gap-20">
          <div className="flex align-center gap-10 w-50">
            <label htmlFor="">Produto/Serviço</label>
            <div className="box-white border-input-lead" style={{ overflow: 'hidden' }}>
              <Select
                onChange={handleChange}
                value={idCurriculoSelecionado}
                className="w-100 input-box"
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option && option?.text?.toLowerCase().includes(input.toLowerCase())
                }>
                {curriculos?.map?.((item) =>
                  <Select.Option key={item.id} value={item.id} text={item?.descricaoCurriculo}>{item?.descricaoCurriculo}</Select.Option>)}
              </Select>
            </div>
          </div>
          <div className="flex align-center gap-10 flex-fill">
            <label htmlFor="">Preço</label>
            <div className="box-white border-input-lead">
              <InputNumber
                placeholder={'R$ 0000,00'}
                value={valorSelecionado}
                onChange={setValorSelecionado}
                decimalSeparator=','
                formatter={currencyFormatter}
                parser={currencyParser}
                className="w-100 input-box"
              />

            </div>
          </div>
          <div className="flex align-center gap-10 w-100">
            <label htmlFor="">Observações do produto (opcional)</label>
            <div className="box-white border-input-lead">
              <Input
                placeholder="Digite sua observação"
                className="input-box"
                value={dsObservacao}
                maxLength={200}
                onChange={(e) => setDsObservacao(e.target.value)}
              />
            </div>
          </div>
        </form>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 25 }}>
          <Button onClick={handleOpenModalAddProduto} className="button-bronce" icon={<AddIcon style={{ color: 'var(--white)' }} />}>
            Add Produto/ Serviço
          </Button>
        </div>
      </div>
      <table className="w-100 mb-20">
        <thead>
          <tr>
            <th className="w-30 header-row pl-20">Produto/Serviço</th>
            <th className="w-20 header-row">Preço</th>
            <th className="w-50 header-row" colSpan={2}>Observação</th>
          </tr>
        </thead>

        <tbody>
          {
            interesses?.map((produto) => {
              return (
                <tr key={produto.id}>
                  <td className="flex align-center">
                    <div className="circle-cinza">
                      <ShoppingCartIcon sx={{ color: 'var(--cinza-azulado-200)' }} />
                    </div>
                    <p>{produto.curriculo.descricaoCurriculo}</p>
                  </td>
                  <td>
                    <p>{currencyFormatter(produto.vlLeadInteresse)}</p>
                  </td>
                  <td>
                    <p>{produto.dsObservacao || '--'}</p>
                  </td>
                  <td className="flex align-center justify-end gap-10" style={{ cursor: 'pointer' }} onClick={() => onDeleteClick(produto)}>
                    <DeleteOutlineOutlinedIcon sx={{ fontSize: 24, color: 'var(--cinza-azulado-400)' }} />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>

      <ModalResposta
        open={openModalAddProduto}
        onClose={onAdicionarInteresse}
        onCancel={() => setOpenModalAddProduto(false)}
        status="Deseja cadastrar o produto/serviço?"
        text="Confirme os dados antes de cadastrar"
        link=""

      />
    </div>
  )
}
