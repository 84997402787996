import { format, isAfter, isBefore, isToday } from "date-fns";
import { ICrmbLeadTarefaCleanDTO, ICrmbLeadTarefaCustomCleanDTO } from "../models/happy-code-api.model";

export const getStatusLead = (tarefa: ICrmbLeadTarefaCustomCleanDTO | ICrmbLeadTarefaCleanDTO) => {
  if (!tarefa) return 'novo';
  if (!tarefa?.dhTarefa) return 'novo';


  const date = new Date(tarefa?.dhTarefa);
  const horaTarefa = format(new Date(tarefa.hrTarefa), 'HH:mm',)
  date.setHours(parseInt(horaTarefa?.split(':')[0]))
  date.setMinutes(parseInt(horaTarefa?.split(':')[1]))


  if (isAfter(new Date(), date)) {
    return 'atrasado'
  };


  if (isToday(date)) {
    return 'hoje';
  }

  if (isBefore(new Date(), date)) return 'futuro';


}
