import { ICrmbLeadEstagioCleanDTO, ICrmbLeadEstagioDTO, ICrmbLeadEstagioFullDTO } from '../models/happy-code-api.model';
import { baseFindList } from './base/base-find-list.service';

export const useLeadEstagioService = () => {
	const resourceURL = 'crmb/lead-estagio';

	return {
		...baseFindList<ICrmbLeadEstagioCleanDTO, ICrmbLeadEstagioDTO, ICrmbLeadEstagioFullDTO>(resourceURL),
	};
};
